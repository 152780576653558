.Auth-mod-outer {
  display: block;
  width: 100svw;
  height: 100svh;
  background-color: rgba(0, 0, 0, 0.8);
}

.Auth-mod {
  top: 37vh;
  height: 26vh;
  left: 38vw;
  width: 24vw;
}

.Auth-mod h1 {
  padding: 20px;
  margin: 0;
  text-align: center;
}

.Auth-mod b {
  text-wrap: nowrap;
}

.Auth-mod form {
  margin-top: -30px;
}

.Auth-mod button {
  width: 22svw;
  padding: 10px;
  flex: none !important;
}

@media screen and (max-width: 768px) {
  .Auth-mod {
    top: 0;
    left: 0;
    width: 100svw;
    height: 100svh;
    border: none;
    border-radius: 0;
    justify-content: flex-start;
    text-align: center;
  }

  .Auth-mod>* {
    margin: 20px;
  }

  .Auth-mod>h1 {
    text-wrap: wrap;
    margin-top: 10vh;
  }

  .Auth-mod>h1>b {
    text-wrap: nowrap;
  }

  .Auth-mod>form {
    flex: 0;
    margin-top: 0;
  }

  .Auth-mod button,
  .Auth-mod input {
    width: 90svw;
    padding: 10px;
    flex: none !important;
  }

  .Auth-mod input {
    width: 80svw;
  }
}
