@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.New-App {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #eedfa0;
  color: #333;
}

.New-App * {
  font-family: "Inter", sans-serif !important;
  font-optical-sizing: auto;
  color: #555;
}

.New-App .Button {
  width: 100%;
}

.New-App .Button * {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 35px;
  padding: 0;
  text-align: center;
  vertical-align: middle;
  margin-top: 10px;

  background-color: #4b5563;
  border-radius: 0;
  border: none;
  color: white;
  z-index: 10;

  transition-duration: 250ms;
}

.New-App .Button button {
  margin-top: -35px;
  z-index: 20;
  font-weight: bold;
}

.New-App .Button:not(.Inactive):hover div {
  transform: rotate(-3deg) scale(1.02);
}

.New-App .Button:not(.Inactive):hover button {
  transform: scale(1.05);
  background-color: #fcd34d;
}

.New-App .Button.Gray button {
  background-color: #e5e7eb;
  color: #333;
}

.New-App .Button.Gray:hover button {
  color: #fff;
}

.New-App .Button.Gray div {
  background-color: #fde68a;
}

.New-App .Button.Gray:not(.Inactive):hover button {
  background-color: #fbbf24;
}

.New-App .Right-content .Button * {
  width: calc(100% - 15px);
  margin-left: 15px;
}

.New-App .Button.Inactive * {
  cursor: default;
  background-color: #ededed !important;
  color: #aaa !important;
}

.New-App h1 {
  font-size: 1.7em;
  margin: 0;
  padding: 0;
  font-weight: 600;
  color: #333;
}

.New-App h2 {
  font-size: 1.4em;
  margin: 0;
  padding: 0;
  font-weight: 600;
  color: #333;
}

.New-App .Input {
  outline: none;
  border: 1px solid #aaa;
  border-radius: 20px;
  height: 20px;
  width: calc(100% - 20px);
  margin: 5px;
  padding: 5px;
  font-size: 14px;
}

.New-App select.Input {
  padding: 0;
  height: 30px;
}

.New-App label,
.New-App a {
  margin-left: 15px;
  margin-right: 15px;
  font-size: 14px;
  text-align: left;
}

.New-App .Half {
  width: 50%;
}

.New-App .Left-Column .Half:first-child {
  width: calc(50% - 10px);
  margin-right: 10px;
}

.New-App textarea {
  flex: 1;
  width: 100%;
  resize: none;
  padding-left: 15px !important;
  font-weight: normal;
  font-size: 14px !important;
}

.New-App .Row {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.New-App .Row .Left-Column {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  height: 50vh;
  flex: 1;
  margin: 3%;
  flex-direction: column;
}

.New-App .Row .Left-Column p {
  font-size: 16px;
  font-family: "Inter", sans-serif !important;
  color: #555;
}

.New-App .Row .Right-Column {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 44%;
  margin: 3%;
  flex-direction: column;

  border-radius: 25px;
  background-color: white;
  padding: 25px;

  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, .1), 0 10px 10px -5px rgba(0, 0, 0, .04);
}

.New-App .Left-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  margin-right: 15px;
}

.New-App .Right-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  padding-right: 10px;
}

.New-App .Flex-1 {
  flex: 1;
}

.New-App .Display-none {
  display: none;
}

.New-App .Attachments-list {
  flex: 1;
  width: 100%;
  margin-top: 5px;
}

.New-App .Attachment {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.New-App .Attachment p {
  flex: 1;
}

.New-App .Attachment a,
.New-App .Attachment p {
  margin: 0;
  padding: 0;
}

.New-App .Header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 15px 50px;
  background-color: #36404f;
}

.New-App .Header * {
  flex: 1;
  color: white;
  margin: 0;
}

.New-App .Footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 50px;
  background-color: #1f2937;
}

.New-App .Footer * {
  flex: 1;
  color: white;
}

.New-App .Voice-Report {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
}

.New-App .Voice-Report label {
  text-align: center;
  margin-top: 5px;
  width: 70%;
}

#audio {
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .New-App {
    align-items: center;
    overflow-y: auto;
    width: 100%;
  }

  .New-App .Row {
    flex-direction: column;
    align-items: center;
  }

  .New-App .Row .Left-Column {
    margin-top: 20px;
    align-items: center;
  }

  .New-App .Row .Right-Column {
    width: 100%;
    margin: 0;
    padding: 0;
    margin-top: 20px;
    align-items: center;
  }

  .New-App .Row .Left-Column,
  .New-App .Row .Right-Column,
  .New-App .Left-content,
  .New-App .Right-content {
    width: calc(100% - 30px);
    height: auto;
    padding: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    margin: 0;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .New-App .Left-content,
  .New-App .Right-content {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .New-App .Half,
  .New-App .Header,
  .New-App .Footer {
    width: 100% !important;
    padding: 0;
    margin-right: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .New-App .Half {
    margin: 0;
  }

  .New-App .Footer,
  .New-App .Header {
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;
  }

  .New-App .Input {
    width: calc(100% - 30px);
    margin: 5px !important;
  }

  .New-App textarea {
    flex: none;
    width: calc(100% - 50px) !important;
    min-height: 100px;
  }

  .Right-content .Input {
    flex: none;
    width: calc(100% - 40px) !important;
  }

  .New-App .Right-content .Button * {
    width: calc(100% - 30px);
  }
}
