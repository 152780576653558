.Admin {
  width: 100svw;
  height: 100svh;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  justify-content: center;
  align-items: center;
  color: #444;
}

.Admin>.Side-panel {
  width: 20vw;
  height: 50vh;

  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Admin>.Main-panel {
  width: 60vw;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* text-align: center; */
}

.Company {
  display: flex;
  flex-direction: row;
}

.Admin .Content {
  flex: 1;
  display: flex;
  max-height: 100%;
  align-items: flex-start;
  overflow-y: auto;
  flex-direction: column;
}

.Active {
  color: #000;
}

.Active::before {
  color: #000;
  content: '> ';
}

.Error {
  border-color: #e00;
  border-width: 2px;
}

.Sites {
  display: flex;
  flex-direction: column;
}

.Site {
  padding: 5px;
}

.Status-icon {
  text-align: center;
  border: none;
}

.Report:hover {
  color: #000;
  text-decoration: underline;
  cursor: pointer;
}

.Pending {
  color: #000;
  /* font-size: 12px; */
}

.Approved {
  color: #0a0;
}

.Rejected {
  color: #e00;
}

.Statistics {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Statistic {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex: 1;
}

.Report-view {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.Report-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.Report-row>*+* {
  text-align: center;
}

.Report-row>*:last-child {
  text-align: right;
}

.Report-row>*:first-child {
  text-align: left;
}

.Report-content {
  flex: 1;
}

.Report-contact {
  width: 40%;
}

.Report-content-row {
  align-items: flex-start;
  flex: 1;
  height: 100%;
  overflow-y: auto;
}

.Report-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 200px;
  margin: 20px 0;
}

.text-overflow-dynamic-container {
  position: relative;
  max-width: 100%;
  padding: 0 !important;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  vertical-align: text-bottom !important;
}

.text-overflow-dynamic-ellipsis {
  position: absolute;
  white-space: nowrap;
  overflow-y: visible;
  overflow-x: hidden;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  max-width: 100%;
  min-width: 0;
  width: 100%;
  top: 0;
  left: 0;
}

.text-overflow-dynamic-container:after,
.text-overflow-dynamic-ellipsis:after {
  content: '-';
  display: inline;
  visibility: hidden;
  width: 0;
}

.Mock {
  opacity: 0;
  cursor: default !important;
}

.Company-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  gap: 10%;
}

.Company-row p,
.Company-row input {
  margin: 0;
  font-size: 14px;
}

.Company-row input {
  padding: 5px;
}

.Company-row button {
  flex: none;
  width: 20%;
  padding: 5px;
}

.Company-row:has(.Company-new>input[value=""], .Company-new>input:empty, .Company-new>input:not([value])) button {
  border-color: #fff;
}

.Company-row:has(>a:hover) * {
  color: #f00;
  text-decoration: underline;
  cursor: pointer;
}

.Company-new {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
  gap: 20px;
}

.Comment-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.Comment-row a {
  margin-bottom: 10px;
}

.Comment-input {
  margin: 0;
  padding-bottom: 20px;
}

.Comment-button {
  /* margin: 0 10px;
  width: calc(100% - 20px); */
  border-top-left-radius: 0;
  width: 100%;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .Admin {
    flex-wrap: wrap;
  }

  .Admin>.Side-panel {
    width: 80vw;
    height: 5vh;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .Admin>.Side-panel>a {
    display: none;
  }

  .Admin>.Main-panel {
    width: 80vw;
    height: 80vh;
    overflow-y: auto;
  }

  .Report-row {
    flex-direction: column;
    gap: 0px;
    justify-content: center;
  }

  .Report-row>* {
    margin: 5px;
    text-align: left !important;
  }

  .spacing {
    width: 100%;
    height: 10px;
  }

  .Report-content,
  .Report-contact {
    margin: 0;
    margin-top: 20px;
    width: 100%;
  }

  .Report-buttons {
    flex-direction: row;
  }

  .Report-content-row {
    justify-content: flex-start;
  }

  .Company-new,
  .Company-new>input {
    width: 100%;
  }
}
