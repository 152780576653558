@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&display=swap');

body {
  margin: 0;
  /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
  width: 100svw;
  height: 100svh;
  overflow: hidden;
}

* {
  font-family: "Inconsolata", monospace;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

a {
  color: #444;
}

a:hover {
  color: #000;
}

button {
  outline: none;
  border: none;
  background-color: #fff;
  color: #444;
  border: 1px solid #444;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  flex: 1;
}

button:hover:not(:disabled) {
  background-color: #444;
  color: #fff;
}

button:hover:disabled {
  cursor: default;
}

input {
  outline: none;
  border: none;
  color: #444;
  padding: 10px;
  border-bottom: 1px solid #444;
  text-align: center;
  width: 20svw;
  margin-top: 10px;
  font-size: 24px;
}

select {
  outline: none;
  color: #444;
  padding: 10px;
  border: 1px solid #444;
  text-align: center;
  width: 100%;
  margin-top: 10px;
  font-size: 16px;
}

option {
  font-size: 14px;
}

h1 {
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

h1:has(+select) {
  margin-top: 30px;
}

.textarea {
  position: relative;
  outline: none;
  border: none;
  color: #444;
  padding-left: 10px;
  border-left: 1px solid #444;
  width: 90%;
  margin: 10px;
  resize: none;
  font-size: 14px;
  overflow-y: visible;
  text-align: left;
}

.textarea[value=""],
.textarea:not([value]) {
  height: 10vh;
}

[contenteditable][placeholder]:empty:before,
[contenteditable][placeholder]:not([value]):before,
[contenteditable][placeholder][value=""]:before {
  content: attr(placeholder);
  position: absolute;
  left: 10px;
  top: 0;
  color: gray;
  background-color: transparent;
}

table {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  height: min(fit-content, 100%);
  max-height: min(fit-content, 100%);
}

th {
  text-align: left;
  border-bottom: 1px solid #444;
  color: #000;
  font-weight: normal;
}

td>div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

b {
  color: #000;
}

p:has(b) {
  width: 100%;
  text-align: right;
}

.Modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 40vh;
  left: 30vw;
  width: 40vw;
  height: 20vh;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid #444;
  border-radius: 15px;
  z-index: 100;
}

.Modal>p {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.Modal>.Buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.Modal>.Buttons>button {
  flex: 1;
  margin: 20px;
}

.Modal-outer {
  display: none;
  content: '';
  position: fixed;
  top: 0vh;
  left: 0vw;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 100;
}

.Modal>form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

h1 {
  color: #444;
}

.Error {
  color: #d00;
}

.mobile {
  display: none;
}

.Footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background-color: #ddd1;
  width: 4px;
}

::-webkit-scrollbar-thumb {
  width: 4px;
  background-color: #7771;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  width: 6px;
  border-radius: 3px;
  background-color: #444;
}

@media screen and (max-width: 768px) {
  tr {
    border-bottom: 5px solid #444;
  }

  tr:last-child {
    border-bottom: none;
  }

  table thead {
    display: none
  }

  table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  table td::before {
    content: attr(data-label) '\A';
    white-space: pre;
    font-weight: bold;
    color: #000;
  }

  .mobile {
    display: block;
  }

  .non-mobile {
    display: none;
  }

  .Footer {
    margin-top: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }

  .Modal {
    left: 10vw;
    width: 80vw;
  }

  ::-webkit-scrollbar-thumb:hover {
    width: 4px;
    border-radius: 2px;
  }

  .textarea {
    height: 20vh;
  }
}

pre {
  text-wrap: pretty;
}
